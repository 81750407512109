// This file can be replaced during build by using the `fileReplacements` array.
// `npm run build -- --configuration=imac-test` replaces `environment.ts` with `environment.imac-test.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  wallboards: {
    staff: {
      stats: {
        title: 'IMAC Test Call Centre',
        baseUrl: 'https://82gaie4x2c.execute-api.ap-southeast-2.amazonaws.com/test/stats',
        mockData: null,
      },
      agents: {
        baseUrl: 'https://82gaie4x2c.execute-api.ap-southeast-2.amazonaws.com/test/agents',
        mockData: null,
      },
    },
  },
  auth: {
    cognitoAwsRegion: 'ap-southeast-2',
    cognitoUserPoolId: 'ap-southeast-2_gtuqqgIIq',
    cognitoDomain: 'uoapool',
    cognitoClientId: '7ocm4nhve9kds6vm5j7qvl81h',
    redirectUri: 'https://d3emnqbwtvsz6n.cloudfront.net',
    logoutUri: '',
    scopes: 'openid profile https://imac.test.auckland.ac.nz/wallboard-spa',
    codeChallengeMethod: 'S256',
  },
  privateUrlKeyWords: {
    whoNeedBearerToken: [{ url: '82gaie4x2c.execute-api.ap-southeast-2.amazonaws.com', optional: true }],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
