import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from '../../core/pipes/pipes.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { WhiteLogoComponent } from './white-logo/white-logo.component';

import { AgentCardComponent } from './agent-card/agent-card.component';
import { CardFrameComponent } from './card-frame/card-frame.component';
import { AuthGuard } from '@uoa/auth';
import { StatusSummaryComponent } from './status-summary/status-summary.component';
import { StatsBoardComponent } from '../call-centres/stats-board/stats-board.component';
import { AgentBoardComponent } from '../call-centres/agent-board/agent-board.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    StatsBoardComponent,
    AgentBoardComponent,
    WhiteLogoComponent,
    AgentCardComponent,
    CardFrameComponent,
    StatusSummaryComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule,
    PipesModule,
    RouterModule.forChild([
      // Staff Service Centre
      {
        path: 'staff-stats',
        canActivate: [AuthGuard],
        component: StatsBoardComponent,
        data: {
          agents: environment.wallboards.staff.agents,
          stats: environment.wallboards.staff.stats,
        },
      },
      {
        path: 'staff-tv-stats/:key',
        component: StatsBoardComponent,
        data: {
          agents: environment.wallboards.staff.agents,
          stats: environment.wallboards.staff.stats,
        },
      },
    ]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WallboardsModule {}
