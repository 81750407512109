import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers, Storage } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AuthModule, CognitoConfigService, StorageService } from '@uoa/auth';
import { ErrorPagesModule } from '@uoa/error-pages';
import { WallboardsModule } from 'src/app/pages/wallboard/wallboards.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStorageService } from './core/services';
import { AppAuthDesktopConfig } from './auth.desktop.config';

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    AuthModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__wallboards',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    AppRoutingModule,
    ErrorPagesModule,
    WallboardsModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: CognitoConfigService, useClass: AppAuthDesktopConfig },
    { provide: StorageService, useClass: AppStorageService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
